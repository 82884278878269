/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import Popup from 'reactjs-popup';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';

const ErrorMessage = ({ message }) => (
  <div className="popup-content__description">
    Oops, unfortunately something went wrong:
    <div dangerouslySetInnerHTML={ {
      __html: message,
    } }
    />
  </div>
);

const ThankYou = () => (
  <div className="popup-content__description">
    Thank you!
    <br />
    If you want to post a comment about CinQ, please do so
    { ' ' }
    <Link to="/comments">here</Link>
  </div>
);

const FormSubmitted = ({ result, message }) => {
  if (result === 'success') {
    return <ThankYou />;
  }
  return <ErrorMessage message={ message } />;
};

const Form = ({ setEmail, setName, handleSubmit }) => (
  <>
    <h2 className="popup-content__title">
      Stay in
      <em> CinQ </em>
      <br />
      Stay in touch
    </h2>
    <div className="popup-content__description">
      Sign up to receive occasional email updates on new content and submitters.
    </div>
    <form onSubmit={ handleSubmit }>
      <div className="form-item form-item--name">
        <input
          type="text"
          placeholder="Your first name"
          name="name"
          onChange={ e => setName(e.target.value) }
        />
      </div>
      <div className="form-item form-item--email">
        <input
          type="email"
          placeholder="email"
          name="email"
          onChange={ e => setEmail(e.target.value) }
        />
      </div>
      <div className="form-submit">
        <input className="" type="submit" value="Sign me up!" />
      </div>
    </form>
    <div className="popup-content__unsubscribe">
      You can unsubscribe at any time. To view our privacy policy, click
      { ' ' }
      <Link to="https://www.kaust.edu.sa/en/privacy-policy">here.</Link>
    </div>
  </>
);

const MailchimpPopup = () => {
  const [ submitted, setSubmitted ] = useState(false);
  const [ submissionResult, setSubmissionResult ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ name, setName ] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(email, {
      FNAME: name,
    });

    setEmail('');
    setName('');
    setSubmissionResult(result);
    setSubmitted(true);
  };

  return (
    <div className="newsletter">
      <Popup trigger={ <button className="button">Sign up for updates</button> } modal>
        { close => (
          <div className="popup-content__wrapper">
            <div className="popup-content__inner">
              { submitted
                ? (
                  <FormSubmitted
                    result={ submissionResult.result }
                    message={ submissionResult.msg }
                  />
                )
                : <Form handleSubmit={ handleSubmit } setName={ setName } setEmail={ setEmail } /> }
              <button className="popup-content__close" onClick={ close }>
                X CLOSE
              </button>
            </div>
          </div>
        ) }
      </Popup>
    </div>
  );
};

export default MailchimpPopup;
