/* eslint-disable react/button-has-type */
import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState } from 'react';

function Header() {
  const [ isExpanded, toggleExpansion ] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  return (
    <header className="header">

      <div className="header__inner">

        <div className="header__branding">
          <div className="header__branding__title">
            <Link to="/">
              { site.siteMetadata.title }
            </Link>
          </div>

          <div className="header__branding__description">
            <Link to="/about">
              { site.siteMetadata.author }
            </Link>
          </div>
        </div>

        <div className="header__navigation">
          <button
            className="header__toggle"
            onClick={ () => toggleExpansion(!isExpanded) }
          >
            <svg
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <nav
            className={ `${
              isExpanded ? 'is-open' : ''
            } header__menus` }
          >
            <div className="header__menus__inner">
              <ul className="main-menu">
                { [
                  {
                    route: '/look',
                    title: 'Look',
                    class: 'look',
                  },
                  {
                    route: '/listen',
                    title: 'Listen',
                    class: 'listen',
                  },
                  {
                    route: '/learn',
                    title: 'Learn',
                    class: 'learn',
                  },
                  {
                    route: '/grow',
                    title: 'Grow',
                    class: 'grow',
                  },
                ].map(link => (
                  <li key={ link.route } className="main-menu__item">
                    <Link
                      className={ `block main-menu__link main-menu__link--${link.class}` }
                      key={ link.title }
                      to={ link.route }
                    >
                      { link.title }
                    </Link>
                  </li>
                )) }
              </ul>

              <ul className="secondary-menu">
                <li className="secondary-menu__item">
                  <Link to="/list">
                    Index
                  </Link>
                </li>
                <li className="secondary-menu__item">
                  <Link to="/comments">
                    Your comments
                  </Link>
                </li>
                <li className="secondary-menu__item">
                  <Link to="/submit-profile">
                    SUBMIT
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

    </header>
  );
}

export default Header;
