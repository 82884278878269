/* eslint-disable react/button-has-type */
import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState } from 'react';

import ShareButtons from './ShareButtons';
import MailchimpPopup from './MailchimpPopup';

function Footer() {
  const [ isExpanded, toggleExpansion ] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteFooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <div className="footer__inner">

        <div className="footer__newsletter">
          <MailchimpPopup />
        </div>

        <div className="footer__share">

          <div className="footer__share__title">
            Share this
          </div>

          <button
            className="footer__share__toggle"
            onClick={ () => toggleExpansion(!isExpanded) }
          >
            Share this
          </button>

          <div
            className={ `${
              isExpanded ? 'is-open' : ''
            } footer__share__content` }
          >
            <ShareButtons />
          </div>
        </div>

        <nav className="footer__nav">
          <Link to="/about">
            { site.siteMetadata.title }
          </Link>
          { ' ' }
          brought to you by
          { ' ' }
          <a href="mailto: artsoffice@kaust.edu.sa" target="_blank" rel="noreferrer">
            Office of the Arts
          </a>
        </nav>

        <ul className="footer__social">
          <li className="footer__social__item footer__social__item--instagram">
            <a href="https://www.instagram.com/kaustxcinq">
              Instagram
            </a>
          </li>
          <li className="footer__social__item footer__social__item--facebook">
            <a href="https://www.facebook.com/officeofthearts">
              Facebook
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
