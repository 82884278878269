import PropTypes from 'prop-types';
import React from 'react';

import Header from './Header';
import Footer from './Footer';

// Include general styles to be applied throughout the website.
import '../styles/main.scss';

function Layout({ children, pageClass }) {
  return (
    <div className={ `page ${pageClass}` }>
      <Header />

      <main className="page__content">
        { children }
      </main>

      <div className="page__bottom">
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageClass: PropTypes.string,
};

Layout.defaultProps = {
  pageClass: '',
};

export default Layout;
